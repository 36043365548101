import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { changeLanguage } from 'i18next'

const LanguageSelector = () => {
  const { t } = useTranslation()
  const locales = t('locales', { lng: 'ru' }, { returnObjects: true }).split(
    ', '
  )
  const languages = locales.map(code => ({
    code,
    label: t(code, { lng: 'ru' }),
  }))
  const [currentLang, setCurrentLang] = useState('ru')
  const visibleLanguages = languages.filter(lang => lang.code !== currentLang)
  return (
    <>
      {visibleLanguages.map((lang, index) => (
        <React.Fragment key={lang.code}>
          <span
            className="nav"
            onClick={() => {
              changeLanguage(lang.code)
              setCurrentLang(lang.code)
            }}
          >
            {lang.label}
          </span>
          {index < visibleLanguages.length - 1 && <>&nbsp;/&nbsp;</>}
        </React.Fragment>
      ))}
    </>
  )
}

export default LanguageSelector
