// Item.js
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { motion } from 'framer-motion'
import { FaPlus } from 'react-icons/fa6'
import { ShowName, ShowNote } from './ShowGoods'
import { FormatToAnchor } from './FormatToAnchor'

export const Item = props => {
  const [currentItem, setCurrentItem] = useState(null)
  const { t } = useTranslation()

  const handleClick = () => {
    setCurrentItem(props.item.id)
  }

  const generateJsonLd = () => {
    return {
      '@context': 'https://schema.org/',
      '@type': 'Product',
      name: t('n_' + props.item.id, { lng: 'ru' }),
      image: `https://www.teonaperfume.ru/img/${props.item.id}.jpg`,
      description:
        t(t('c_' + props.item.id), { lng: 'ru' }) +
        '; ' +
        t('aroma_group', { lng: 'ru' }) +
        ': ' +
        t('g_' + props.item.id, { lng: 'ru' }) +
        '; ' +
        t('high_notes', { lng: 'ru' }) +
        ': ' +
        t('h_' + props.item.id, { lng: 'ru' }) +
        '; ' +
        t('heart_notes', { lng: 'ru' }) +
        ': ' +
        t('m_' + props.item.id, { lng: 'ru' }) +
        '; ' +
        t('base_notes', { lng: 'ru' }) +
        ': ' +
        t('l_' + props.item.id, { lng: 'ru' }) +
        '; ' +
        t('desc', { lng: 'ru' }) +
        ': ' +
        t('d_' + props.item.id, { lng: 'ru' }).replace(/<1\/>/g, ' '),
      brand: 'Teona Perfume',
      offers: {
        '@type': 'Offer',
        priceCurrency: 'RUB',
        price: t('p_' + props.item.id),
        url: `https://www.teonaperfume.ru/#${FormatToAnchor(
          t('n_' + props.item.id, { lng: 'ru' })
        )}`,
        itemCondition: 'https://schema.org/NewCondition',
        availability: 'https://schema.org/InStock',
        priceValidUntil: new Date(
          new Date().setFullYear(new Date().getFullYear() + 1)
        )
          .toISOString()
          .split('T')[0], // Действует год
        hasMerchantReturnPolicy: {
          '@type': 'MerchantReturnPolicy',
          returnPolicyCategory:
            'https://schema.org/MerchantReturnFiniteReturnWindow',
          applicableCountry: 'RU', // Specify the country code
        },
        shippingDetails: {
          '@type': 'OfferShippingDetails',
          shippingRate: {
            '@type': 'MonetaryAmount',
            value: '0.00',
            currency: 'RUB',
          },
          shippingDestination: {
            '@type': 'DefinedRegion',
            name: 'Worldwide',
          },
          deliveryTime: {
            '@type': 'ShippingDeliveryTime',
            businessDays: {
              '@type': 'OpeningHoursSpecification',
              dayOfWeek: [
                'https://schema.org/Monday',
                'https://schema.org/Tuesday',
                'https://schema.org/Wednesday',
                'https://schema.org/Thursday',
                'https://schema.org/Friday',
                'https://schema.org/Saturday',
                'https://schema.org/Sunday',
              ],
              opens: '09:00',
              closes: '21:00',
            },
          },
        },
      },
      aggregateRating: {
        '@type': 'AggregateRating',
        ratingValue: '5',
        reviewCount: '1', // Set a positive value
      },
      review: {
        '@type': 'Review',
        author: {
          '@type': 'Person',
          name: 'Anonymous', // Updated author to an object
        },
        datePublished: new Date().toISOString().split('T')[0],
        reviewBody: 'No reviews available.',
        reviewRating: {
          '@type': 'Rating',
          bestRating: '5',
          ratingValue: '5',
          worstRating: '1',
        },
      },
    }
  }

  return (
    <>
      <motion.div
        className="item"
        id={FormatToAnchor(t('n_' + props.item.id, { lng: 'ru' }))}
        layoutId={`card-${props.item.id}`}
        onClick={handleClick}
        style={{
          zIndex: currentItem === props.item.id ? 20 : 10,
        }}
      >
        <picture onClick={() => props.onShowItem(props.item)}>
          <source
            srcSet={'./img/' + props.item.id + '.webp'}
            type="image/webp"
          />
          <img
            src={'./img/' + props.item.id + '.jpg'}
            width="450"
            height={t('c_' + props.item.id) === 'd' ? '300' : '600'}
            alt={t('n_' + props.item.id)}
          />
        </picture>

        <div className="item-info">
          <h2 onClick={() => props.onShowItem(props.item)}>
            {ShowName(props.item.id, t)}
          </h2>
          <div onClick={() => props.onShowItem(props.item)}>
            <ul className="notes-list">
              <li>{ShowNote('g_' + props.item.id, 'aroma_group', t)}</li>
              <li>{ShowNote('h_' + props.item.id, 'high_notes', t)}</li>
              <li>{ShowNote('m_' + props.item.id, 'heart_notes', t)}</li>
              <li>{ShowNote('l_' + props.item.id, 'base_notes', t)}</li>
            </ul>
            <span className="more" onClick={() => props.onShowItem(props.item)}>
              {t('more')}...
            </span>
          </div>
          <div className="item-price">
            <b onClick={() => props.onShowItem(props.item)}>
              {new Intl.NumberFormat().format(t('p_' + props.item.id))}
              &nbsp;{t('rub')}
            </b>
          </div>
          <div className="add-to-cart" onClick={() => props.onAdd(props.item)}>
            <FaPlus />
          </div>
        </div>
      </motion.div>
      {/* JSON-LD structured data */}
      <script type="application/ld+json">
        {JSON.stringify(generateJsonLd())}
      </script>
    </>
  )
}

export default Item
