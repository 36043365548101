import React, { useState, useEffect, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import {
  FaChevronLeft,
  FaChevronRight,
  FaCircleXmark,
  FaLink,
  FaCheck,
} from 'react-icons/fa6'
import { useSwipeable } from 'react-swipeable'
import { motion } from 'framer-motion'
import { ShowName, ShowNote } from './ShowGoods'
import { FormatToAnchor } from './FormatToAnchor'
import { QRCodeSVG } from 'qrcode.react'

const ShowFullItem = ({ item: initialItem, items, onShowItem, onAdd }) => {
  const { i18n, t } = useTranslation()
  const dot = i18n.language === 'cn' ? '。' : '.'
  const [item, setItem] = useState(initialItem)
  const [changeItem, setChangeItem] = useState(false)
  const [direction, setDirection] = useState(1)
  const [isCopied, setIsCopied] = useState(false)
  const [isHovered, setIsHovered] = useState(false)

  const updateHash = useCallback(() => {
    const anchor = FormatToAnchor(t('n_' + item.id))
    window.location.hash = anchor
    document.title = `${t('logo')} - ${t('n_' + item.id)}`
  }, [item.id, t])

  useEffect(() => {
    updateHash(item)
  }, [item, updateHash])

  const nextItemSwipe = useCallback(() => {
    setChangeItem(true)
    setDirection(1)
    const currentIndex = items.findIndex(i => i.id === item.id)
    const nextIndex = (currentIndex + 1) % items.length
    const nextItem = items[nextIndex]
    setItem(nextItem)
    setIsCopied(false)
    setIsHovered(false)
    updateHash(nextItem)
  }, [item, items, updateHash])

  const nextItem = event => {
    event.stopPropagation()
    nextItemSwipe()
  }

  const prevItemSwipe = useCallback(() => {
    setChangeItem(true)
    setDirection(-1)
    const currentIndex = items.findIndex(i => i.id === item.id)
    const prevIndex = (currentIndex - 1 + items.length) % items.length
    const prevItem = items[prevIndex]
    setItem(prevItem)
    setIsCopied(false)
    setIsHovered(false)
    updateHash(prevItem)
  }, [item, items, updateHash])

  const prevItem = event => {
    event.stopPropagation()
    prevItemSwipe()
  }

  const closeFullItem = useCallback(() => {
    setChangeItem(false)
    setIsCopied(false)
    onShowItem(null)
    window.history.pushState(
      '',
      document.title,
      window.location.pathname + window.location.search
    )
    document.title = `${t('logo')}`
  }, [onShowItem, t])

  useEffect(() => {
    const handleKeyDown = event => {
      if (event.key === 'ArrowRight') {
        nextItemSwipe()
      } else if (event.key === 'ArrowLeft') {
        prevItemSwipe()
      } else if (event.key === 'Escape') {
        closeFullItem()
      }
    }
    window.addEventListener('keydown', handleKeyDown)
    return () => {
      window.removeEventListener('keydown', handleKeyDown)
    }
  }, [item, nextItemSwipe, prevItemSwipe, closeFullItem])

  const handlers = useSwipeable({
    onSwipedLeft: nextItemSwipe,
    onSwipedRight: prevItemSwipe,
  })

  const copyToClipboard = event => {
    event.stopPropagation()
    const anchor = FormatToAnchor(t('n_' + item.id, { lng: 'ru' }))
    const url = `${window.location.origin}#${anchor}`
    navigator.clipboard.writeText(t('url_finded') + url)
    setIsCopied(true)
    setTimeout(() => setIsCopied(false), 500)
  }

  return (
    <div className="full-item" onClick={() => closeFullItem()}>
      <motion.div
        layoutId={changeItem ? `full-card-${item.id}` : `card-${item.id}`}
        initial={{ x: changeItem ? 1000 * direction : 0, opacity: 0 }}
        animate={{ x: 0, opacity: 1 }}
        exit={{ x: changeItem ? -1000 * direction : 0, opacity: 0 }}
        transition={{
          type: 'spring',
          stiffness: 300,
          damping: 25,
          duration: 0.25,
        }}
        key={item.id}
        {...handlers}
      >
        <picture onClick={() => closeFullItem()}>
          <source srcSet={'./img/' + item.id + '.webp'} type="image/webp" />
          <img
            src={'./img/' + item.id + '.jpg'}
            alt={t('n_' + item.id)}
            width="600"
            height={t('c_' + item.id) === 'd' ? '450' : '900'}
          />
        </picture>
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 0.3 }}
        >
          <FaChevronLeft
            className="fa-button"
            style={{ left: 40, top: 400 }}
            onClick={event => prevItem(event)}
          />
          <FaChevronRight
            className="fa-button"
            style={{ right: 40, top: 400 }}
            onClick={event => nextItem(event)}
          />
          <FaCircleXmark
            className="close-button"
            onClick={() => closeFullItem()}
          />
        </motion.div>
        <h2>{ShowName(item.id, t)}</h2>
        <p>{ShowNote('g_' + item.id, 'aroma_group', t)}</p>
        <p>
          {ShowNote('h_' + item.id, 'high_notes', t)}
          {ShowNote('m_' + item.id, 'heart_notes', t)}
          {ShowNote('l_' + item.id, 'base_notes', t)}
        </p>
        <p>
          {ShowNote('d_' + item.id, 'desc', t)}
          {dot}
        </p>
        <b>
          {new Intl.NumberFormat().format(t('p_' + item.id))} {t('rub')}
        </b>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <span className="add-to-cart-full" onClick={() => onAdd(item)}>
            {t('to_cart')}
          </span>
          <span
            className="copy-link"
            onClick={copyToClipboard}
            style={{ cursor: 'pointer' }}
          >
            {isCopied ? <FaCheck /> : <FaLink />}
          </span>
          <div
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
            onClick={e => {
              setIsHovered(!isHovered)
              copyToClipboard(e)
              e.stopPropagation()
            }}
            style={{
              position: 'relative',
              display: 'inline-flex',
              alignItems: 'center',
              cursor: 'pointer',
            }}
          >
            <QRCodeSVG
              value={`${window.location.origin}#${FormatToAnchor(
                t('n_' + item.id, { lng: 'ru' })
              )}`}
              size={50}
              fgColor={'#ca5252'}
            />
            {isHovered && (
              <div
                style={{
                  position: 'absolute',
                  top: '-150px',
                  left: '-5px',
                  transform: 'translateX(-50%)',
                  zIndex: 1,
                  padding: '10px',
                  backgroundColor: '#fff',
                  border: '1px solid #ddd',
                  boxShadow: '0 2px 10px rgba(0,0,0,0.1)',
                }}
              >
                <QRCodeSVG
                  value={`${window.location.origin}#${FormatToAnchor(
                    t('n_' + item.id, { lng: 'ru' })
                  )}`}
                  size={110}
                  fgColor={'#ca5252'}
                />
              </div>
            )}
          </div>
        </div>
      </motion.div>
    </div>
  )
}

export default ShowFullItem
