import React, { useMemo } from 'react'
import Item from './Item'

const Items = ({ items, onShowItem, onAdd }) => {
  const renderedItems = useMemo(() => {
    return items.map(el => (
      <Item onShowItem={onShowItem} key={el.id} item={el} onAdd={onAdd} />
    ))
  }, [items, onShowItem, onAdd])

  return <main>{renderedItems}</main>
}

export default Items
