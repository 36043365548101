import React from 'react'
import { FaBagShopping } from 'react-icons/fa6'
import { motion, AnimatePresence } from 'framer-motion'
import { Trans, useTranslation } from 'react-i18next'
import Slider from './Slider'
import LanguageSelector from './LanguageSelector'

const Header = props => {
  const { i18n, t } = useTranslation()
  // Определение знака пунктуации в зависимости от текущей локали
  const dot = i18n.language === 'cn' ? '。' : '.'
  const NonBreakingSpace = () => <>&nbsp;</>

  return (
    <header>
      <menu>
        <a href="#root">
          <motion.span
            animate={{ x: [-100, 0] }}
            transition={{
              type: 'spring',
              stiffness: 260,
              damping: 20,
            }}
            className="logo"
          >
            <span style={{ display: 'flex', alignItems: 'center' }}>
              <img
                src="./img/png/favicon-32x32.png"
                width={32}
                height={32}
                alt={t('logo')}
                style={{
                  filter:
                    'brightness(0) saturate(100%) invert(37%) sepia(94%) saturate(3224%) hue-rotate(329deg) brightness(84%) contrast(94%)',
                }}
              />
              {t('logo')}
            </span>
          </motion.span>
        </a>
        <ul className="nav">
          <li onClick={() => props.onShowCart(props.item)}>
            &nbsp;
            <FaBagShopping
              className={`shop-cart-button ${props.showCart ? 'active' : ''}`}
            />
            &nbsp;
            {props.orders.length > 0 ? (
              <AnimatePresence>
                <motion.span
                  key={props.orders.length}
                  className="badge"
                  initial={{ opacity: 0 }}
                  animate={{ y: [200, 0], opacity: 1 }}
                  exit={{ opacity: 0 }}
                  transition={{ ease: 'linear', duration: 0.5 }}
                >
                  {props.orders.reduce((total, el) => total + el.quantity, 0)}
                </motion.span>
              </AnimatePresence>
            ) : (
              ''
            )}
          </li>
          <li>
            <a href="#about">{t('about')}</a>
          </li>
          <li>
            <a href="#catalog">{t('catalog')}</a>
          </li>
          <li>
            <a href="#contacts">{t('contacts')}</a>
          </li>
          <li>
            <LanguageSelector />
          </li>
        </ul>
      </menu>
      <Slider />
      <span id="about"></span>
      <div id="about" className="about">
        <Trans
          i18nKey="about_t"
          components={{ 1: <br />, 2: <NonBreakingSpace /> }}
        />
        {dot}
      </div>
    </header>
  )
}

export default Header
