import React, { useEffect, useState } from 'react'
import { motion } from 'framer-motion'
import { useTranslation } from 'react-i18next'
import emailjs from '@emailjs/browser'
import { FaCircleXmark } from 'react-icons/fa6'

const ShowFinal = props => {
  const { orders, clearOrders, onShowFinal, onShowCart } = props
  const { t } = useTranslation()
  const [finalOrder, setFinalOrder] = useState('')
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [phone, setPhone] = useState('')
  const [comment, setComment] = useState('')
  const [emailSent, setEmailSent] = useState(false)

  // Инициализация emailjs
  useEffect(() => {
    emailjs.init('ar6Bwk9NcqF6NJHfi')
  }, [])

  // Загружаем данные из localStorage, если они есть
  useEffect(() => {
    setName(localStorage.getItem('name') || '')
    setEmail(localStorage.getItem('email') || '')
    setPhone(localStorage.getItem('phone') || '')
    setComment(localStorage.getItem('comment') || '')
  }, [])

  // Сохраняем данные в localStorage при изменении
  const handleChange = setter => event => {
    const { value } = event.target
    setter(value)
    localStorage.setItem(event.target.name, value)
  }

  const handleSubmit = event => {
    event.preventDefault()
    const subject = 'ORDER FROM TEONAPERFUME.RU'

    const body = `
      Клиент: ${name}
      Номер телефона: ${phone}
      Адрес почты: ${email}

      Содержание заказа:
      ${finalOrder.join('\n')}

      Комментарий:
      ${comment}
    `

    const templateParams = {
      email: t('emailaddr'),
      message: body,
    }

    emailjs
      .send(
        'service_yrjpb9r',
        'template_2rq9x1o',
        templateParams,
        'ar6Bwk9NcqF6NJHfi'
      )
      .then(
        response => {
          setEmailSent(true)
          console.log('SUCCESS!', response.status, response.text)

          // Очищаем корзину после успешной отправки
          clearOrders()

          // Отправляем заказ в Telegram
          sendTelegramMessage(body)
        },
        error => {
          console.log('FAILED...', error)
          window.open(
            `mailto:${t(
              'emailaddr'
            )}?subject=${subject}&body=${encodeURIComponent(body)}`
          )
        }
      )

    onShowCart()
  }

  // Функция для отправки сообщения в Telegram
  const sendTelegramMessage = message => {
    const telegramBotToken = '7800880854:AAG5mswLH47VtPemJ-5hi4LhXVl0-QFwTAA'
    const telegramChatId = '-1002430983466'

    fetch(`https://api.telegram.org/bot${telegramBotToken}/sendMessage`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        chat_id: telegramChatId,
        text: `Новый заказ:\n${message}`,
      }),
    })
      .then(response => response.json())
      .then(data => console.log('Сообщение в Telegram отправлено!', data))
      .catch(error => console.error('Ошибка при отправке в Telegram:', error))
  }

  // Формируем описание заказа
  useEffect(() => {
    setFinalOrder(orders.map(el => `${t('n_' + el.id)} (${el.quantity}шт.)`))
  }, [orders, t])

  if (emailSent) {
    return (
      <div className="full-item">
        <motion.div
          animate={{ x: [1000, 0] }}
          exit={{ x: [0, 1000] }}
          transition={{ ease: 'easeInOut' }}
        >
          <FaCircleXmark
            className="close-button"
            onClick={() => onShowFinal()}
          />
          <h2>{t('order_sent')}</h2>
          {t('callback_soon')}
          <div className="add-to-cart-full" onClick={() => onShowFinal()}>
            {t('close_word')}
          </div>
        </motion.div>
      </div>
    )
  }

  return (
    <div className="full-item">
      <motion.div
        animate={{ x: [-1000, 0] }}
        exit={{ x: [0, -1000] }}
        transition={{ ease: 'easeInOut' }}
      >
        <h2>{t('final_order')}</h2>
        {t('want')}:&nbsp;
        {orders.map(el => (
          <span key={el.id}>
            «{t('n_' + el.id)}»&nbsp;({el.quantity}&nbsp;{t('pc')}),{' '}
          </span>
        ))}
        {t('final_price')}&nbsp;
        {new Intl.NumberFormat().format(
          orders.reduce(
            (acc, el) => acc + t('p_' + el.id) * parseInt(el.quantity),
            0
          )
        )}
        &nbsp;{t('rub')}.
        <br />
        <br />
        {t('send_contacts')}.
        <br />
        <br />
        <form onSubmit={handleSubmit}>
          <input name="order" defaultValue={finalOrder} hidden />
          <label>{t('fio')}*</label>
          <input
            name="name"
            required
            maxLength="80"
            placeholder={t('fio_example')}
            value={name}
            onChange={handleChange(setName)}
          />
          <label>{t('phone')}*</label>
          <input
            name="phone"
            type="phone"
            required
            maxLength="18"
            placeholder={t('phone_example')}
            value={phone}
            onChange={handleChange(setPhone)}
          />
          <label>{t('email')}*</label>
          <input
            name="email"
            type="email"
            required
            maxLength="30"
            placeholder={t('mail_example')}
            value={email}
            onChange={handleChange(setEmail)}
          />
          <label>{t('comment')}</label>
          <textarea
            name="comment"
            maxLength="600"
            value={comment}
            onChange={handleChange(setComment)}
          />
          <input
            type="submit"
            className="add-to-cart-full"
            style={{ width: '92%' }}
            value={t('send')}
          />
        </form>
        <FaCircleXmark className="close-button" onClick={() => onShowFinal()} />
      </motion.div>
    </div>
  )
}

export default ShowFinal
