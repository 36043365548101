import React from 'react'
import { FaTrashCan, FaCirclePlus, FaCircleMinus } from 'react-icons/fa6'
import { useTranslation } from 'react-i18next'

const Order = ({ item, decreaseQuantity, increaseQuantity, onDelete }) => {
  const { t } = useTranslation()

  return (
    <div className="item">
      <img
        src={'./img/' + item.id + '.jpg'}
        alt={item.id}
        style={{
          minHeight: '60px',
        }} /* чтобы цена не провалилась под картинку */
      />
      <h2>{t('n_' + item.id)}</h2>
      <p>{t(t('c_' + item.id))}</p>
      <b>
        {new Intl.NumberFormat().format(t('p_' + item.id) * item.quantity)}
        {t('rub')}
      </b>
      <span className="quantity" style={{ right: '115px' }}>
        {item.quantity}
      </span>
      <FaCircleMinus
        className="delete-icon"
        style={{ right: '118px' }}
        onClick={() => decreaseQuantity(item.id)}
      />
      <FaCirclePlus
        className="delete-icon"
        style={{ right: '35px' }}
        onClick={() => increaseQuantity(item.id)}
      />
      <FaTrashCan className="delete-icon" onClick={() => onDelete(item.id)} />
    </div>
  )
}

export default Order
