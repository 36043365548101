import React from 'react'
import { Trans } from 'react-i18next'

// Неразрывный пробел
const NonBreakingSpace = () => <>&nbsp;</>

// Отображение название аромата и иконки гендера
export const ShowName = (itemId, t) => {
  const gender = {
    m: '♂',
    w: '♀',
    u: '♂♀',
    d: '◦',
  }
  return (
    <>
      {t('n_' + itemId)}
      <span className="gender">{gender[t('c_' + itemId)]}</span>
    </>
  )
}

// Проверка и отображение нот аромата
export const ShowNote = (noteKey, noteLabel, t) => {
  const note = t(noteKey)
  return note.length > 2 ? (
    <>
      <em>{t(noteLabel)}:&nbsp;</em>
      <Trans
        i18nKey={note}
        components={{ 1: <br />, 2: <NonBreakingSpace /> }}
      />
    </>
  ) : null
}
