import React from 'react'
import { useTranslation } from 'react-i18next'
import { FaTelegram, FaWhatsapp, FaVk } from 'react-icons/fa6'

const phoneReplace = phone => {
  return '+' + phone.replace(/\D/g, '') // Убираем все нецифровые символы
}

const Footer = () => {
  const { t } = useTranslation()

  return (
    <footer>
      <div id="contacts">
        <a href={'tel:' + phoneReplace(t('phonenum'))}>
          {t('phone')}: {t('phonenum')}
        </a>
        <br />
        <a href={'mailto:' + t('emailaddr')}>
          {t('email')}: {t('emailaddr')}
        </a>
        <br />
        &copy;&nbsp;{t('from_year')}-{new Date().getFullYear()}&nbsp;
        {t('allrights')}
        <br />
        <a
          href={'https://t.me/' + phoneReplace(t('phonenum'))}
          aria-label={t('telegram_text')}
          target="_blank"
          rel="noopener noreferrer"
        >
          <FaTelegram className="icon" />
        </a>
        <a
          href={'https://wa.me/' + phoneReplace(t('phonenum'))}
          aria-label={t('whatsapp_text')}
          target="_blank"
          rel="noopener noreferrer"
        >
          <FaWhatsapp className="icon" />
        </a>
        <a
          href={'https://vk.com/' + t('vk')}
          aria-label={t('vk_text')}
          target="_blank"
          rel="noopener noreferrer"
        >
          <FaVk className="icon" />
        </a>
      </div>
    </footer>
  )
}

export default Footer
