import React, { useEffect, useState } from 'react'
import Header from './components/Header'
import Footer from './components/Footer'
import Items from './components/Items'
import Categories from './components/Categories'
import ShowFullItem from './components/ShowFullItem'
import ShowFinal from './components/ShowFinal'
import Cart from './components/Cart'
import { useTranslation } from 'react-i18next'
import i18next from 'i18next'
import { AnimatePresence } from 'framer-motion'
import { FormatToAnchor } from './components/FormatToAnchor'

const App = props => {
  const { t } = useTranslation()

  // Состояния приложения
  const [orders, setOrders] = useState(
    JSON.parse(localStorage.getItem('orders')) || []
  )
  const [currentItems, setCurrentItems] = useState([])
  const [items, setItems] = useState([])
  const [fullItem, setFullItem] = useState(null) // Состояние для текущего элемента
  const [showFullItem, setShowFullItem] = useState(false)
  const [showFinal, setShowFinal] = useState(false)
  const [showCart, setShowCart] = useState(false)

  // Заполняем массив items при первой загрузке
  useEffect(() => {
    const loadedItems = t('items_order')
      .split(', ')
      .map(Number)
      .reduce((acc, id) => {
        if (i18next.exists('n_' + id)) {
          acc.push({
            id: '' + id,
            category: t('c_' + id),
            quantity: 1,
          })
        }
        return acc
      }, [])

    setItems(loadedItems)
    setCurrentItems(loadedItems) // Устанавливаем текущие товары
  }, [t])

  // Сохраняем корзину в localStorage при изменении orders
  useEffect(() => {
    localStorage.setItem('orders', JSON.stringify(orders))
  }, [orders])

  // Прокрутка к якорю и открытие ShowFullItem при первой загрузке
  useEffect(() => {
    const hash = window.location.hash.slice(1) // Убираем # из хеша
    if (hash) {
      // Ищем элемент по хешу
      const itemToShow = items.find(
        item => FormatToAnchor(t('n_' + item.id)) === hash
      )
      if (itemToShow) {
        setShowFullItem(true) // Открываем ShowFullItem
        setFullItem(itemToShow) // Устанавливаем текущий элемент
      }
      // Прокручиваем до соответствующего элемента
      document.getElementById(hash)?.scrollIntoView({ behavior: 'instant' })
    }
  }, [items, t])

  // Функции для управления состоянием
  const clearOrders = () => {
    setOrders([])
    localStorage.removeItem('orders')
  }

  const addToOrder = item => {
    setOrders(prevOrders => {
      const existingItem = prevOrders.find(el => el.id === item.id)

      if (existingItem) {
        return prevOrders.map(el =>
          el.id === item.id ? { ...el, quantity: el.quantity + 1 } : el
        )
      } else {
        return [...prevOrders, { ...item, quantity: 1 }]
      }
    })
  }

  const deleteOrder = id => {
    setOrders(prevOrders => prevOrders.filter(el => el.id !== id))
  }

  const increaseQuantity = id => {
    setOrders(prevOrders =>
      prevOrders.map(el =>
        el.id === id ? { ...el, quantity: el.quantity + 1 } : el
      )
    )
  }

  const decreaseQuantity = id => {
    setOrders(prevOrders => {
      const updatedOrders = prevOrders
        .map(el =>
          el.id === id
            ? { ...el, quantity: el.quantity > 1 ? el.quantity - 1 : 0 }
            : el
        )
        .filter(el => el.quantity > 0)
      return updatedOrders
    })
  }

  const chooseCategory = category => {
    if (category === 'a') {
      setCurrentItems(items)
    } else {
      setCurrentItems(items.filter(el => el.category === category))
    }
  }

  const onShowItem = item => {
    setFullItem(item)
    setShowFullItem(true)
  }

  return (
    <div className="wrapper">
      <Header
        onShowFinal={() => setShowFinal(prev => !prev)}
        onShowCart={() => setShowCart(prev => !prev)}
        showCart={showCart}
        orders={orders}
      />
      <AnimatePresence>
        {showCart && (
          <Cart
            onShowCart={() => setShowCart(false)}
            onShowFinal={() => setShowFinal(true)}
            onDelete={deleteOrder}
            increaseQuantity={increaseQuantity}
            decreaseQuantity={decreaseQuantity}
            orders={orders}
          />
        )}
      </AnimatePresence>
      <Categories chooseCategory={chooseCategory} />
      <Items onShowItem={onShowItem} items={currentItems} onAdd={addToOrder} />
      <AnimatePresence>
        {showFullItem && (
          <ShowFullItem
            item={fullItem}
            items={currentItems}
            onAdd={addToOrder}
            onShowItem={() => setShowFullItem(false)} // Закрытие ShowFullItem
          />
        )}
      </AnimatePresence>
      <AnimatePresence>
        {showFinal && (
          <ShowFinal
            onShowFinal={() => setShowFinal(false)}
            orders={orders}
            clearOrders={clearOrders}
          />
        )}
      </AnimatePresence>
      <Footer />
    </div>
  )
}

export default App
